<template>
  <div>
    <toggle-button
      class="large-font"
      id="premiumType"
      v-model="rowData.is_free"
      @change="tagVideo"
      :sync="true"
      :width="130"
      :height="40"
      :labels="{
        checked   : 'FREE',
        unchecked : 'PREMIUM'
      }"
      :color="{
        checked   : '#75c791',
        unchecked : '#82c7eb',
      }"
      switch-color="#fffff"
    />
  </div>
</template>

<script>
  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    data() {
      return {
        isPremium : this.rowData.is_free,
      }
    },
    methods : {

      /**
       * Tag Video
       */
      tagVideo() {
        if (this.rowData.is_free) {
          this.$http.post('/api/tag/video', {
            tagId   : 2,
            mediaId : this.rowData.media_id,
          });
        } else {
          this.$http.delete('/api/tag/video', {
            data : {
              tagId   : 2,
              mediaId : this.rowData.media_id,
            },
          });
        }
      },
    },
  }
</script>