var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('toggle-button',{staticClass:"large-font",attrs:{"id":"premiumType","sync":true,"width":130,"height":40,"labels":{
      checked   : 'FREE',
      unchecked : 'PREMIUM'
    },"color":{
      checked   : '#75c791',
      unchecked : '#82c7eb',
    },"switch-color":"#fffff"},on:{"change":_vm.tagVideo},model:{value:(_vm.rowData.is_free),callback:function ($$v) {_vm.$set(_vm.rowData, "is_free", $$v)},expression:"rowData.is_free"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }